<template>
    <div class="p-4" v-if="calendarLink">
        <div class="w-full p-3 flex flex-col space-y-2 text-center">
            <p class="text-sm">Connect to your google calendar to sync your sessions with your schedule.</p>
            <a :href="calendarLink" target="_blank">
                <button
                    class="bg-white flex items-center justify-center space-x-2 px-4 py-4 shadow-md border-1 border-orange-tint rounded-xl w-full">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/768px-Google_Calendar_icon_%282020%29.svg.png"
                        alt="" class="w-8">
                    <p class="text-sm font-bold text-left">Connect your calendar</p>
                </button>
            </a>
        </div>
    </div>
</template>
 
<script>
export default {
    data() {
        return {
            calendarLink: false,
        }
    },
    created() {
        // this.$store.dispatch('sessionStore/getCalendarLink').then(res => this.calendarLink = res);
    },
}
</script>
