module.exports = {
  isEmail: (value) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regex.test(value)) return "must be a valid email!";
  },

  minLength: (value, min) => {
    if (!value || !value.length || value.length < min) return `must be longer than ${min} characters`;
  },

  required: (value) => {
    if (!value) return "can't be empty!";
  },

  isBetween: (value, min, max) => {
    if (value < min || value > max) return `${min} - ${max} only`;
  },

  isGrade: (value) => {
    const regex = new RegExp("[A-C][+-]?|D");
    if (!regex.test(value))
      return `must be a letter between A and D. +/- are allowed`;
  },

  isUniqueCourse: (value, values) => {
    for (let i = 0; i < values.length; i++) {
      if (value._id == values[i].class._id)
        return `you are already teaching ${value.desc}`;
    }
  },

  isUniqueApplicationCourse: (value, values) => {
    for (let i = 0; i < values.length; i++) {
      if (value._id == values[i].class_info._id)
        return `you are already teaching ${value.desc}`;
    }
  },

  isUniqueClass: (value, values) => {
    for (let i = 0; i < values.length; i++) {
      if (value.desc == values[i].desc)
        return `you are already enrolled in ${value.desc}`;
    }
  },

  isValidCardNumber: (value) => {
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    if (
      !visaRegEx.test(value) &&
      !mastercardRegEx.test(value) &&
      !amexpRegEx.test(value) &&
      !discovRegEx.test(value)
    )
      return "must enter valid card number!";
  },

  // Is grade (between A and F)
  // isUnique
  // Exact length
  // is valid card number
  // Between (class between 18 - 28)
};
