<template>
  <div>
    <div class="my-12 w-44 mx-auto">
      <img :src="require('@/assets/waiting.svg')" alt="" class="w-full" />
    </div>
    <div class="my-12 mx-12">
      <p class="mx-auto font-bold text-xl my-2 text-center">
        Now it's time to play the waiting game...
      </p>
      <p class="mx-auto font-extralight text-center">
        Your application is undergoing review. We will notify you once your
        profile is ready so you can begin your tutoring journey.
      </p>
    </div>
    <button
      class="
        bg-orange
        fixed
        right-4
        bottom-5
        px-6
        py-2
        rounded-full  
        text-center text-white
        font-bold
        text-lg
        capitalize
      "
      @click="this.$router.push(`/`)"
    >
      Done
    </button>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import modal from "./modal.vue";
import tag from "./tag.vue";
import formInput from "./formInput.vue";
import { isEmail, required } from "../helpers/validations";

export default {
  components: { modal, tag, formInput },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      shareModal: false,
      modalKey: 0,
      email: "",
      error: false,
    };
  },
  methods: {
    ...mapActions(["send_invite"]),
    ...mapMutations(["fireToast"]),
    invite() {
      if (required(this.email) || isEmail(this.email)) {
        this.error = true;
        this.fireToast({
          text: `error sending invite - invalid email :(`,
          type: "error",
        });
      } else {
        this.send_invite(this.email);
        this.email = "";
      }
    },
  },
  watch: {
    email: function (val) {
      if (val) this.error = false;
    },
  },
};
</script>