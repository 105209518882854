<template>
  <div>
    <div class="flex items-center justify-center">
      <div class="relative mt-4 mb-2 w-full ml-5 mr-5">
        <div class="bg-orange-tint rounded-full w-8 h-8 flex items-center justify-center text-orange" @click="goSearch">
          <vue-feather size="20" type="plus" />
        </div>
      </div>
    </div>
    <modal :active="showModal" :key="update">
      <div class="w-full mx-auto mb-2 rounded-md bg-orange-tint">
        <p class="text-orange font-bold text-lg py-2 truncate text-center">
          Start teaching {{ selected?.desc }}
        </p>
      </div>

      <div class="flex flex-wrap justify-between mx-4 my-2 w-72">
        <div class="w-16 h-20 my-2 text-left">
          <p class="capitalize">grade</p>
          <input @change="validateGrade()" placeholder="A" class="
              outline-none
              shadow
              transition
              duration-500
              ease-in-out
              focus:ring-2
              focus:ring-orange
              focus:bg-orange-tint
              focus:text-orange
              focus:shadow-lg
              py-2
              px-3
              w-full
              my-2
              rounded-full
              uppercase
            " type="text" maxlength="2" v-model="grade" :class="{
              border: errors.grade,
              'border-red-600': errors.grade,
              'text-red-600': errors.grade,
              'animate-wiggle': errors.grade,
            }" />
          <br />
          <span class="absolute w-60 text-xs font-extralight text-red-600">{{
              errors.grade
          }}</span>
        </div>
        <div class="w-54 h-20 my-2 text-left">
          <p class="capitalize">professor last name</p>
          <input @change="validateName()" placeholder="e.g. smith" class="
              outline-none
              shadow
              transition
              duration-500
              ease-in-out
              focus:ring-2
              focus:ring-orange
              focus:bg-orange-tint
              focus:text-orange
              focus:shadow-lg
              py-2
              px-3
              w-full
              my-2
              rounded-full
            " v-model="professor" :class="{
              border: errors.professor,
              'border-red-600': errors.professor,
              'text-red-600': errors.professor,
              'animate-wiggle': errors.professor,
            }" type="text" />
          <br />
          <span class="text-xs font-extralight text-red-600">{{
              errors.professor
          }}</span>
        </div>
      </div>
      <div class="w-full flex justify-end items-end pt-2">
        <Button size="small" @click="!errors.grade && !errors.professor
          ? application
            ? createApplicationCourse()
            : createCourse()
          : '';
        ">
          Submit
        </Button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  isUniqueCourse,
  isUniqueApplicationCourse,
  isGrade,
  required,
} from "../../helpers/validations";

import searchDropdown from "../inputs/searchDropdown.vue";
import Modal from "../modal.vue";
import Button from "./Button.vue";

export default {
  components: { searchDropdown, Modal, Button },
  computed: {
    ...mapState([
      "class_previewed",
      "user_tutor",
      "tutor_application",
      "user",
      "onboarding_info",
    ]),
    university() {
      if (this.onboarding) return this.onboarding_info.user.university;
      else return this.user.university;
    },
  },
  props: {
    show: Boolean,
    application: {
      type: Boolean,
      default: false,
    },
    onboarding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: this.show,
      update: 0,
      selected: null,
      professor: null,
      grade: null,
      course: null,
      errors: {
        professor: null,
        grade: null,
        course: null,
      },
    };
  },
  emits: ["add"],
  methods: {
    ...mapActions(["create_course", "add_application_class"]),
    ...mapMutations(["setSearchCallback"]),
    validateName() {
      let missing = required(this.professor);
      this.errors.professor = missing ? missing : null;
    },
    validateGrade() {
      let missing = required(this.grade);
      let invalid = !missing ? isGrade(this.grade.toUpperCase()) : false;
      this.errors.grade = missing ? missing : invalid ? invalid : null;
    },
    async createCourse() {
      this.validateName();
      this.validateGrade();
      if (!this.errors.professor && !this.errors.course && !this.errors.grade) {
        await this.create_course({
          id: this.selected._id,
          grade: this.grade,
          professor: this.professor,
        });
        this.showModal = false;
        this.update++;
      }
    },
    createApplicationCourse() {
      this.validateName();
      this.validateGrade();
      if (!this.errors.professor && !this.errors.course && !this.errors.grade) {
        this.add_application_class({
          class_info: this.selected,
          grade: this.grade,
          professor: this.professor,
        });
        this.showModal = false;
        this.update++;
        this.$emit("add", this.selected);
      }
    },
    searchCallback(course) {
      const repeats = this.application
        ? isUniqueApplicationCourse(course, this.tutor_application.classes)
        : this.user_tutor
          ? isUniqueCourse(course, this.user_tutor.courses) : false

      if (repeats) return this.$store.commit("fireToast", {
        text: repeats,
        type: "error",
      });

      window.history.back();
      this.selected = course;
      this.showModal = true;
      this.update++;
    },
    goSearch() {
      this.$router.push('/class-search');
      this.setSearchCallback(this.searchCallback);
    }
  },
};
</script>

