<template>
  <label>
    <p class="text-orange text-lg my-2 text-left capitalize">{{ label }}</p>
    <input
      v-if="!textArea"
      :type="type || 'text'"
      :name="name"
      :value="value"
      class="
        outline-none
        border-2 border-orange-tint
        focus:border-orange focus:text-orange
        rounded-lg
        p-2
        mb-4
        truncate
        transition
        duration-300
        ease-in-out
        shadow-inner
      "
      :class="{
        'w-36': short,
        'w-full': !short,
        'text-center': otp,
        'text-xl': otp,
        'appearance-none': type != 'number',
      }"
      :style="otp ? 'letter-spacing: 0.8em;' : ''"
      :placeholder="placeholder"
      @change="onInput($event.target.value)"
    />
    <textarea
      v-else
      class="
        appearance-none
        outline-none
        border-2 border-orange-tint
        focus:border-orange focus:text-orange
        rounded-2xl
        p-2
        mb-4
        truncate
        w-full
        transition
        duration-300
        ease-in-out
        shadow-inner
      "
      :class="{ 'h-48': !small, 'h-24': small }"
      :name="name"
      :value="value"
      @change="onInput($event.target.value)"
      id=""
      cols="3"
      rows="10"
      :placeholder="placeholder"
    ></textarea>
    <error :errors="errors" class="-mt-4" />
  </label>
</template>

<script>
import { isEmail, required } from "../helpers/validations";
import error from "./error.vue";

export default {
  components: { error },
  props: {
    label: {
      type: String,
      required: true,
    },
    type: String,
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    short: {
      type: Boolean,
      default: false,
    },
    otp: {
      type: Boolean,
      default: false,
    },
    textArea: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    email: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value", "error", "clean"],
  computed: {
    name() {
      return this.label.toLowerCase();
    },
  },
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    onInput(event) {
      this.$emit("update:value", event);
    },
    validate(val) {
      this.errors = [];
      if (this.required && required(val)) this.errors.push(required(val));
      if (this.email && isEmail(val)) this.errors.push(isEmail(val));
      if (this.errors.length > 0) this.$emit("error", this.errors);
      else this.$emit("clean");
    },
  },
  watch: {
    value: function () {
      this.validate(this.value);
    },
  },
};
</script>
