<template>
  <div>
    <modal :active="showModal">
      <div class="w-full mx-auto mb-2 rounded-md bg-orange-tint">
        <p class="text-orange font-bold text-lg py-2 truncate text-center">
          teach a class
        </p>
      </div>

      <div class="flex flex-wrap justify-between mx-4">
        <div class="w-72 mb-2 text-left">
          <search-dropdown
            @select="
              selected = $event;
              validateCourse();
            "
            @reset="selected = null"
            name="class search"
            placeholder="search classes by name or abbreviation"
            :request="{
              path: `https://api.tutoruu.com/api/class/search/${university._id}/`,
              body: {},
            }"
          />
          <span class="text-xs font-extralight text-red-600">{{
            errors.course
          }}</span>
        </div>
        <div class="w-16 h-20 my-2 text-left">
          <p class="capitalize">grade</p>
          <input
            @change="validateGrade()"
            placeholder="A"
            class="
              outline-none
              shadow
              transition
              duration-500
              ease-in-out
              focus:ring-2
              focus:ring-orange
              focus:bg-orange-tint
              focus:text-orange
              focus:shadow-lg
              py-2
              px-3
              w-full
              my-2
              rounded-full
              uppercase
            "
            type="text"
            maxlength="2"
            v-model="grade"
            :class="{
              border: errors.grade,
              'border-red-600': errors.grade,
              'text-red-600': errors.grade,
              'animate-wiggle': errors.grade,
            }"
          />
          <br />
          <span class="absolute w-60 text-xs font-extralight text-red-600">{{
            errors.grade
          }}</span>
        </div>
        <div class="w-54 h-20 my-2 text-left">
          <p class="capitalize">professor last name</p>
          <input
            @change="validateName()"
            placeholder="e.g. smith"
            class="
              outline-none
              shadow
              transition
              duration-500
              ease-in-out
              focus:ring-2
              focus:ring-orange
              focus:bg-orange-tint
              focus:text-orange
              focus:shadow-lg
              py-2
              px-3
              w-full
              my-2
              rounded-full
            "
            v-model="professor"
            :class="{
              border: errors.professor,
              'border-red-600': errors.professor,
              'text-red-600': errors.professor,
              'animate-wiggle': errors.professor,
            }"
            type="text"
          />
          <br />
          <span class="text-xs font-extralight text-red-600">{{
            errors.professor
          }}</span>
        </div>
      </div>
      <div class="w-full flex justify-end items-end pt-6">
        <button
          class="
            py-2
            px-5
            relative
            text-center
            rounded-2xl  
            font-bold
            transition
            duration-300
            ease-out
          "
          :class="{
            'bg-gray-300 text-shark': !selected || errors.course || errors.grade || errors.professor,
            'bg-orange text-white': selected && !errors.course && !errors.grade && !errors.professor,
          }"
          @click="
            selected && !errors.course && !errors.grade && !errors.professor
              ? application
                ? createApplicationCourse()
                : createCourse()
              : ''
          "
        >
          <span v-if="selected">Teach {{ selected.desc }}</span>
          <span v-else>Select Class</span>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  isUniqueCourse,
  isUniqueApplicationCourse,
  isGrade,
  required,
} from "../helpers/validations";
import Modal from "./modal.vue";
import searchDropdown from "./inputs/searchDropdown.vue";

export default {
  components: { searchDropdown, Modal },
  computed: {
    ...mapState([
      "class_previewed",
      "user_tutor",
      "tutor_application",
      "user",
      "onboarding_info",
    ]),
    university() {
      if (this.onboarding) return this.onboarding_info.user.university;
      else return this.user.university;
    },
  },
  props: {
    show: Boolean,
    application: {
      type: Boolean,
      default: false,
    },
    onboarding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: this.show,
      selected: null,
      professor: null,
      grade: null,
      errors: {
        professor: null,
        grade: null, 
        course: null,
      },
    };
  },
  emits: ["add"],
  methods: {
    ...mapActions(["create_course", "add_application_class"]),
    validateName() {
      let missing = required(this.professor);
      this.errors.professor = missing ? missing : null;
    },
    validateCourse() {
      let missing = required(this.selected);
      let repeats = this.application
        ? isUniqueApplicationCourse(
            this.selected,
            this.tutor_application.classes
          )
        : this.user_tutor
        ? isUniqueCourse(this.selected, this.user_tutor.courses)
        : false;
      this.errors.course = missing ? missing : repeats ? repeats : null;
    },
    validateGrade() {
      let missing = required(this.grade);
      let invalid = !missing ? isGrade(this.grade.toUpperCase()) : false;
      this.errors.grade = missing ? missing : invalid ? invalid : null;
    },
    async createCourse() {
      this.validateName();
      this.validateGrade();
      this.validateCourse();
      if (!this.errors.professor && !this.errors.course && !this.errors.grade) {
        await this.create_course({
          id: this.selected._id,
          grade: this.grade,
          professor: this.professor,
        });
        this.showModal = false;
      }
    },
    createApplicationCourse() {
      this.validateName();
      this.validateGrade();
      this.validateCourse();
      if (!this.errors.professor && !this.errors.course && !this.errors.grade) {
        this.add_application_class({
          class_info: this.selected,
          grade: this.grade,
          professor: this.professor,
        });
        this.showModal = false;
        this.$emit("add", this.selected);
      }
    },
  },
};
</script>
