<template>
  <div class="my-36 text-center mx-12">
    <p class="mx-auto font-bold text-xl my-2">Submit transcript</p>
    <p class="mx-auto px-2 font-extralight">
      This is to verify enrollment, grades, and overall academic performance
    </p>
    <button class="
        bg-orange
        mx-auto
        my-8
        px-6
        py-2
        rounded-full  
        text-center
        flex
        items-center
      " v-if="!fileUrl">
      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAABgklEQVR4nO2ZQU7CQBiF3xBPoltvwJZTaHTPCdzqAcCNJia6xXggl3ICVgbXPDZDbGoL7dD2Sfu+hKQt0/b7H9OBGQBjjDHGGDNMgurGJAOAKwAbAB8hBKpcOodkIPnMX95IjtRenVBQ/HBC2FN8/0OoUHx/Q4jFv+QKXZVsM7aVDdCNUvLJv5J8yOzfk3zqXU/YU/woFp0NIPQuBJKzsq6dDyAeK3pU5toqjoDkuqj4+N6fAOLxfAjfbTq23b0eAawBzAFMq/zai22mAGYAfuI1+kdZD+ia0x1gGsIBqAXUOAC1gBoHoBZQ4wDUAmocgFpAjQNQC6hxAGoBNcoAlpntL5XEmerGABYAditE70KPYVP5z4e4PH0ddxchhE07Smm07kfyNrOGd9PoxRsg1a/OIHie2b6ocV5XJPn5a1AtoMYBqAXUOAC1gJrBB5A6FxiTvGvU5HjGKSelBjCJr5OnziOwPNzk31B5el2nB+ymr5e1dbrlE55eG2OMMcaYg2wBd8X2D8AFpyMAAAAASUVORK5CYII="
        class="w-8 h-8 mx-2" />
      <label class="text-white font-bold text-lg">Upload
        <input type="file" class="hidden" @change="fileInput" :disabled="processing" />
      </label>
    </button>
    <div v-else>
      <p>{{ myFile ? myFile.name : "" }}</p>
      <div class="flex w-full justify-center">
        <a :href="fileUrl" target="_blank">
          <button class="
              bg-orange-tint
              mx-2
              my-8
              px-6
              py-2
              rounded-full  
              text-center
              flex
              items-center
            ">
            <p class="text-orange font-bold text-lg">Preview</p>
          </button>
        </a>
        <button class="
            bg-orange
            mx-2
            my-8
            px-6
            py-2
            rounded-full  
            text-center
            flex
            items-center
          ">
          <label class="text-white font-bold text-lg">Replace
            <input type="file" class="hidden" @change="fileInput" :disabled="processing" />
          </label>
        </button>
      </div>
    </div>
    <button v-if="fileUrl" class="
        bg-orange
        fixed
        right-4
        bottom-5
        px-6
        py-2
        rounded-full  
        text-center text-white
        font-bold
        text-lg
        capitalize
      " @click="matching()">
      Next - Demand Matching
    </button>
  </div>
</template>

<script>
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      myFile: null,
      processing: false,
      fileUrl: this.$store.state.tutor_application.transcript,
      step: this.$store.state.tutor_application.step,
    };
  },
  computed: {
    ...mapState(["username"]),
  },
  methods: {
    ...mapActions(["upload_transcript", "update_application_field"]),
    ...mapMutations(["updateDetails"]),
    async fileInput(event) {
      const file = event.target.files[0];
      this.myFile = file;
      try {
        if (file && file.name) {
          this.processing = true;

          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.addEventListener("load", () => {
            // this is to load image on the UI
            // .. not related to file upload :)
            this.fileURL = fr.result;
          });
          const fileData = new FormData();
          fileData.append("file", this.myFile);
          const filePath = `transcripts/${this.username}/${Date.now()}-${file.name
            }`;
          const metadata = { contentType: this.myFile.type };
          const storage = getStorage();
          const transcriptRef = ref(storage, filePath);
          const uploadTask = uploadBytesResumable(
            transcriptRef,
            this.myFile,
            metadata
          );
          uploadTask.on(
            "state_changed",
            (snapshot) => {

            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case "storage/unauthorized":
                  // User doesn't have permission to access the object
                  break;
                case "storage/canceled":
                  // User canceled the upload
                  break;
                case "storage/unknown":
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                this.fileUrl = downloadURL;
                this.upload_transcript(this.fileUrl);
              });
            }
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.processing = false;
      }
    },
    async matching() {
      if (this.$store.state.tutor_application.step < 2)
        await this.update_application_field({
          prop: "step",
          value: 2,
        });
      this.$router.push(`/${this.username}/apply?step=2`)
    },
  },
};
</script>