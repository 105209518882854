<template>
    <span v-if="errors.length > 0" class="text-red-500 font-extralight text-xs py-2">{{ errors[0] }}</span>
</template>

<script>
    export default {
        props: {
            errors: Array
        }
    }
</script>