<template>
  <label class="relative">
    <p class="font-extralight text-base md:text-lg peer-focus:text-orange py-1.5 capitalize">
      {{ name }}
    </p>
    <div class="relative">
      <input :name="name" @click.stop="selectedItem ? '' : (show_options = true)"
        @focusin="selectedItem ? '' : (show_options = true)" @keydown="getItems()"
        @input="getItems()" v-model="inputValue" :disabled="disabled"
        :placeholder="placeholder" type="text"
        class="w-full  max-w-3xl h-12  p-3 border-2 border-gray-200 bg-white rounded-3xl placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-orange" />
      <vue-feather v-if="!show_options" @click.stop="addItem()" class="absolute -mx-8 my-3 mr-5" stroke="orange"
        type="plus" size="24">
      </vue-feather>
      <vue-feather v-else-if="(inputValue.length > 0)" @click.stop="(show_options = false); inputValue = ''"
        class="absolute -mx-8 my-3 mr-5" stroke="gray" type="x-circle" size="20">
      </vue-feather>
    </div>
    <div :key="update" v-if="show_options"
      class="absolute w-full max-h-80 mt-1 overflow-y-auto bg-white shadow-lg rounded-xl z-50">
      <transition-group name="scale-up" appear tag="ul" class="relative">
        <li v-for="(item, index) in items" :key="index">
          <div @click.stop="selectItem(item)"
            class="flex w-full py-3 px-4 cursor-pointer text-left hover:shadow-md hover:bg-orange-tint hover:text-orange transition duration-200 ease-in-out text-shark text-sm truncate">
            <slot v-if="slotted" :item="item">
              {{ item.name }}
            </slot>
            <span v-else>{{ item.name }}</span>
          </div>
        </li>
      </transition-group>
    </div>
  </label>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      selectedItem: null,
      inputValue: "",
      items: [],
      show_options: false,
      update: 0,
    };
  },
  emits: ["select", "change", "add"],
  props: {
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    slotted: {
      type: Boolean,
      default: false,
    },
    request: Object,
    name: {
      type: String,
      default: "",
    },
  },
  methods: {
    selectItem(item) {
      this.$emit("select", item);
      this.selectedItem = item;
      this.inputValue = item.name;
      this.show_options = false;
    },
    addItem() {
      if (!!this.selectedItem) this.$emit("add");
      this.inputValue = "";
      this.selectedItem = null;
    },
    hide() {
      let hide = setTimeout(() => {
        this.show_options = false;
      }, 800);
    },
    async getItems() {
      this.show_options = true;
      if (this.inputValue.length > 0)
        await axios
          .get(`${this.request.path}${this.inputValue}`, this.request.body)
          .then((data) => {
            this.items = data.data;
          })
          .catch((error) => {
            console.error(error.response);
          });
      this.update++;
    },
  },
  watch: {
    items: function () {
      this.$emit("change", this.items);
    },
  },
};
</script>

<style>

</style>
