<template>
  <label class="relative">
    <p
      class="
        font-extralight
        text-base
        md:text-lg
        peer-focus:text-orange
        py-1.5
        capitalize
      "
    >
      {{ name }}
    </p>
    <div class="relative w-full h-5">
      <select
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        class="
          w-full
          peer
          cursor-pointer
          max-w-3xl
          appearance-none
          outline-none
          bg-white
          rounded-xl
          placeholder-gray-500
          ring-orange
          focus:ring-offset-4
          py-2
          px-4
          focus:placeholder-white
          ring-4 ring-opacity-50
          focus:ring-opacity-100
          transition
          duration-200
          ease-out
          text-shark
          focus:text-orange
          shadow-inner
          focus:shadow-none
          peer
        "
        @change="select"
      >
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
        >
          {{ map && !!option.value ? option.value[map] : option.title }}
        </option>
      </select>
      <div
        class="
          absolute
          right-5
          top-1/2
          w-4
          h-4
          -translate-y-1/2
          transition
          duration-300
          ease-out
          text-orange
        "
      >
        <chevron />
      </div>
    </div>
  </label>
</template>

<style scoped>
select:focus ~ div {
  transform: rotate(180deg);
}
</style>

<script>
import chevron from "../../icons/chevron.vue";
export default {
  components: { chevron },
  props: {
    options: Array,
    name: {
      type: String,
      default: "",
    },
    map: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },
  emits: ["select"],
  methods: {
    select(e) {
      this.$emit("select", this.options[e.target.options.selectedIndex].value);
    },
  },
};
</script>
