<template>
  <page-layout v-if="user" :title="steps[this.$route.query.step || tutor_application.step]"  linkDefault="/" :backButton="true" >
    <questionnaire v-if="this.$route.query.step == 'questionnaire' || this.$route.query.step == 0 || !this.$route.query.step" />
    <transcript-upload v-if="this.$route.query.step == 'transcript' || this.$route.query.step == 1" />
    <demand-matching v-if="this.$route.query.step == 'demand' || this.$route.query.step == 2" />
  </page-layout>
</template>

<script >
import PageLayout from "../components/base/pageLayout.vue";
import { useRouter } from "vue-router";
import Questionnaire from "../components/questionnaire.vue";
import { mapState } from "vuex";
import TranscriptUpload from "../components/TranscriptUpload.vue";
import DemandMatching from "../components/DemandMatching.vue";

export default {
  name: "TutorApplication",
  components: {
    PageLayout,
    Questionnaire,
    TranscriptUpload,
    DemandMatching,
  },
  data() {
    return {
      steps: ["Application Form", "Transcript Upload", "Review", "Next Steps"],
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  computed: {
    ...mapState(["tutor_application", "user"]),
  },
};
</script>