<template>
  <div class="m-4 pb-12">
    <div class="w-full py-4 px-4 rounded-2xl  my-4">
      <div class="flex flex-wrap justify-between w-full items-center">
        <div clas="w-full flex justify-center my-1">
          <div class="flex flex-row">
          <div class="flex flex-col justify-start  items-start">
            <img
              class="rounded-full block mx-auto w-20 lg:w-24 h-24 lg:h-24"
              :src="this.img"
              alt=""
            />
           
          </div>
          <div class="flex flex-col mx-4 my-5">
            <p class="font-poppins font-bold text-xl ">{{user.name}}</p>
            <p class="font-poppins font-normal text-xs" >{{user.email}}</p>
          </div>
          </div>
     <div class="flex flex-row">
       <label
              class="
                text-center
                flex flex-col
                items-center
                text-orange
                tracking-wide
                uppercase
                cursor-pointer
                w-20
                p-2
              "
            >
              <span class=" ring-2 ring-orange  rounded-2xl p-1 text-base font-bold leading-normal capitalize"
                >upload</span
              >
              <input
                type="file"
                class="hidden"
                accept="image/png, image/jpeg"
                @input="fileInput"
                :disabled="processing"
              />
            </label>
       </div>
        </div>
   
      <div class="flex flex-col w-full mb-4 ">
          <p class=" mx-2 capitalize py-2 font-poppins font-bold text-sm">bio</p>
          <textarea
            @change="checkEdit()"
            class="
            h-12
            pl-5
            pr-5
           -mx-1
            border-2 border-gray-200
            bg-white
            rounded-3xl
            placeholder-gray-500
             p-2.5
             mb-2
            "
            v-model="bio"
          />
         
        </div>
 <hr>
<div class="flex flex-row space-x-8">
     
        <div>
          <p class="mx-2 capitalize py-2 font-poppins font-bold text-sm">phone number</p>
          <input
            @change="checkEdit()"
            type="number"
            class="
              max-w-3xl
            h-12
            pl-5
            pr-5
            border-2 border-gray-200
            bg-white
            rounded-3xl
            placeholder-gray-500
            w-44
            p-2.5
            -mx-1
            "
            v-model="phone"
          />
        </div>
   <div>
          <p class="mx-2 capitalize py-2 font-poppins font-bold text-sm">class of</p>
          <input
            @input="
              checkEdit();
              validateClassOf();
            "
            class="
            max-w-3xl
            h-12
            pl-5
            pr-5
            border-2 border-gray-200
            bg-white
            rounded-3xl
            placeholder-gray-500
            w-28
             p-2.5
            "
            placeholder="e.g. 23"
            v-model="classOf"
            type="number"
            min="18"
            max="28"
            :class="{
              'border-gray-200 text-shark': !errors.classOf,
              'border border-red-600 text-red-600': errors.classOf,
            }"
          />
          <span class="text-xs font-extralight text-red-600">{{
            errors.classOf
          }}</span>
        </div>

      </div>
      </div>
    </div>

  <hr>
  <Calendar />
  <hr>

    <div class="w-full flex items-center justify-between px-5">
      <p class="font-poppins text-sm font-bold">Classes </p>
      <course-search :application="true" @add="courseKey++"/>
    </div>

 <div class="flex flex-wrap gap-2 mb-5 mt-4 ml-3 mr-2 " :key="courseKey">
      <filter-tag v-for="course in tutor_application.classes"
        :key="course._id"> {{ course.class_info.desc }}</filter-tag>
     </div> 

    <hr>
    <div class="w-full  px-4 rounded-2xl my-5">
      <p class="font-poppins font-bold text-sm ">Tutoring information</p>
      <ol class="list-decimal">
        <li class="my-4 mx-4">
          <p class="py-2 font-poppins font-normal text-sm">Specify an hourly rate *</p>
          <div class="flex flex-row items-start">
            <input
              type="Number"
              class="
                transition
                appearance-none
                border
                duration-500
                ease-in-out
                ring-shark
                focus:ring-orange
                py-2
                px-3
                w-44
                rounded-3xl
              "
              placeholder="Min:50 Max:300"
              v-model="rate"
              :max="300"
              :min="50"
              @change="autosave('rate', this.rate)"
            />
            <p class="font-poppins text-orange text-xs leading-4 px-5">
              You'll get<br />
              <span
                class="font-bold"
                :class="{
                  'text-green-600': rate >= 50 && rate <= 300,
                  'text-orange': rate < 50 || rate > 300,
                }"
                >{{
                  rate >= 50 && rate <= 300
                    ? Math.floor(rate * 92.75) / 100
                    : "??"
                }}/hr</span
              >
            </p>
          </div>
          <!-- <p class="font-extralight text-sm flex justify-start">
            <span>Min: 50</span><span class="mx-4">Max: 300</span>
          </p> -->
        </li>
        <!-- <li class="my-4 mx-4">
          <p class="py-2">E-Wallet</p>
          <p class="text-sm font-extralight pb-2">
            Add the mobile number and service provider associated with your
            mobile E-wallet. You can set that up by visiting your current
            service provider.
          </p>

          <div class="pb-4">
            <p class="text-sm">Service Provider</p>
            <dropdown :options="providers" @select="selectIssuer" />
          </div>
          <p class="text-sm pt-4">Wallet/Phone Number (msisdn)</p>
          <input
            @change="checkEdit(); autosave('ewallet', ewallet);"
            type="number"
            class="
              transition
              duration-500
              ease-in-out
              focus:ring-orange
              focus:bg-orange-tint
              focus:text-orange
              focus:shadow-lg
              outline-none
              appearance-none
              py-2
              px-3
              w-40
              rounded-lg
              ring-shark
              bg-white
              shadow-inner
            "
            v-model="ewallet"
          />
        </li> -->
        <li class="my-4 mx-4">
          <p class="py-2 font-poppins font-normal text-sm">How do you want to give your sessions?</p>
          <div class="flex flex-row justify-around w-80">
            <div
              @click="
                this.inperson = false;
                checkEdit();
                autosave('inperson', false);
              "
              class="space-y-2"
            >
              <div
                class="
                  w-6
                  h-6
                  mx-auto
                  ring-2
                  rounded-full
                  transition
                  duration-300
                  ease-in-out
                "
                :class="{
                  'ring-orange': !inperson,
                  'ring-shark': inperson,
                  'rounded-tr-sm': !inperson,
                  'bg-orange-tint': !inperson,
                }"
              ></div>
              <p
                class="font-poppins font-normal text-xs text-center"
                :class="{ 'text-orange': !inperson, 'font-bold': !inperson }"
              >
                online only
              </p>
            </div>
            <div
              @click="
                this.inperson = true;
                checkEdit();
                autosave('inperson', true);
              "
              class="mx-10 space-y-2"
            >
              <div
                class="
                  w-6
                  h-6
                  mx-auto
                  ring-2
                  rounded-full
                  transition
                  duration-300
                  ease-in-out
                "
                :class="{
                  'ring-orange': inperson,
                  'ring-shark': !inperson,
                  'rounded-tr-sm': inperson,
                  'bg-orange-tint': inperson,
                }"
              ></div>
              <p
                class="font-poppins font-normal text-xs text-center"
                :class="{ 'text-orange': inperson, 'font-bold': inperson }"
              >
                online & in person
              </p>
            </div>



          </div>
        </li>
        <li class="my-4 mx-4 font-poppins font-normal text-sm">
          Provide skills and qualifications that you think are relevant to
          teaching. Do you have prior professional experiences that lend
          themselves to tutoring or education in general?
          <textarea
            @change="checkEdit(); autosave('answer_1', this.answer_1);"
            class="
              my-2
              border
              transition
              appearance-none
              outline-none
              duration-500
              ease-in-out
              resize-none
                 border-orange
              py-2
              px-3
              w-full
              h-24
              rounded-2xl
              bg-white
            "
            v-model="answer_1"
          ></textarea>
        </li>
        <li class="my-4 mx-4 font-poppins font-normal text-sm">
          What do you aim to achieve in your role as a tutor?
          <textarea
            @change="checkEdit(); autosave('answer_2', this.answer_2);"
            class="
             my-2
              border
              transition
              appearance-none
              outline-none
              duration-500
              ease-in-out
              resize-none
                 border-orange
              py-2
              px-3
              w-full
              h-24
              rounded-2xl
              bg-white
            "
            v-model="answer_2"
          ></textarea>
        </li>
        <li class="my-4 mx-4 font-poppins font-normal text-sm">
          What teaching skills or strategies distinguish you from other tutors?
          Explain in detail.
          <textarea
            @change="checkEdit(); autosave('answer_3', this.answer_3);"
            class="
              my-2
              border
              transition
              appearance-none
              outline-none
              duration-500
              ease-in-out
              resize-none
              py-2
              px-3
              w-full
              h-24
              rounded-2xl
                 border-orange
              bg-white
            "
            v-model="answer_3"
          ></textarea>
        </li>
        <li class="my-4 mx-4 font-poppins font-normal text-sm">
          What skills do you want to develop in this role, that you may have
          previously struggled to?
          <textarea
            @change="checkEdit(); autosave('answer_5', this.answer_5);"
            class="
               my-2
              border
              transition
              appearance-none
              outline-none
              duration-500
              ease-in-out
              resize-none
              py-2
              px-3
              w-full
              h-24
              rounded-2xl
              bg-white
                 border-orange
            "
            v-model="answer_5"
          ></textarea>
        </li>
      </ol>
    </div>


    <button
      v-if="edited && !errors.classOf"
      class="
        bg-orange
        fixed
        right-4
        bottom-5
        px-6
        py-2
        rounded-full  
        text-center text-white
        font-bold
        text-lg
        capitalize
      "
      @click="save()"
    >
      save
    </button>
    <button
      v-else-if="
        tutor_application.answers[0] !== '' &&
        tutor_application.answers[1] !== '' &&
        tutor_application.answers[2] !== '' &&
        tutor_application.answers[4] !== '' &&
        user.classOf &&
        user.phone &&
        tutor_application.rate &&
        tutor_application.classes.length > 0
      "
      class="
        bg-orange
        fixed
        right-4
        bottom-5
        px-6
        py-2
        rounded-full  
        text-center text-white
        font-bold
        text-lg
        capitalize
      "
      @click="
        if(tutor_application.step < 1) autosave('step', 1)
        this.$router.push(`/${user.username}/apply?step=1`);
      "
    >
      next - transcript
    </button>
    <button
      v-else
      class="
        bg-gray-200
        fixed
        right-4
        bottom-5
        px-6
        py-2
        rounded-full  
        text-center text-shark text-opacity-80
        font-bold
        text-lg
        capitalize
      "
    >
      missing {{ error }}
    </button>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import { isBetween } from "../helpers/validations";
import AddCourse from "../components/addCourse.vue";
import Dropdown from "../components/inputs/dropdown.vue";
import CourseSearch from "../components/Feed/CourseSearch.vue";
import FilterTag from "../components/Feed/FilterTag.vue";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Calendar from "./buttons/Calendar.vue";

export default {
  name: "Questionnaire",
  components: {
    AddCourse,
    Dropdown,
    CourseSearch,
    FilterTag,
    Calendar,
},
  data() {
    return {
      providers: [
        { value: "vodafone", title: "Vodafone" },
        { value: "etisalat", title: "Etisalat" },
        { value: "orange", title: "Orange" },
      ],
      add: false,
      componentKey: 0,
      courseKey: 0,
      edited: false,
      answer_1: this.$store.state.tutor_application.answers[0],
      answer_2: this.$store.state.tutor_application.answers[1],
      answer_3: this.$store.state.tutor_application.answers[2],
      answer_4: this.$store.state.tutor_application.answers[3],
      answer_5: this.$store.state.tutor_application.answers[4],
      bio: this.$store.state.user.bio,
      classOf: this.$store.state.user.classOf,
      img: this.$store.state.user.img_url
        ? this.$store.state.user.img_url
        : this.$store.state.filler_img,
      phone: this.$store.state.user.phone,
      classes_length: this.$store.state.tutor_application.classes.length,
      step: 0,
      rate: this.$store.state.tutor_application.rate,
      inperson: this.$store.state.tutor_application.inperson,
      ewallet: this.$store.state.tutor_application.ewallet,
      issuer: this.$store.state.tutor_application.issuer,
      myFile: null,
      processing: false,
      errors: {
        classOf: null,
      },
    };
  },
  computed: {
    ...mapState(["user", "tutor_application"]),
    error() {
      if (!this.classOf) return "graduating class";
      if (!this.phone) return "phone";
      if (this.tutor_application.classes.length == 0) return "classes";
      if (this.answer_1 == "") return "Question 1";
      if (this.answer_2 == "") return "Question 2";
      if (this.answer_3 == "") return "Question 3";
      if (this.answer_5 == "") return "Question 4";
      if (!this.rate) return "hourly rate";
      // if (!this.ewallet) return "E-Wallet Number";
      // if (!this.issuer) return "E-Wallet Service Provider";
    },
  },
  methods: {
    ...mapMutations(["removeApplicationClass", "addAnswers", "updateDetails"]),
    ...mapActions(["update_personal", "update_application", "update_application_field"]),
    selectIssuer(issuer) {
      this.issuer = issuer;
      this.autosave('issuer', issuer);
      this.checkEdit();
    },
    validateClassOf() {
      let invalid = isBetween(this.classOf ? this.classOf : 23, 18, 28);
      this.errors.classOf = invalid ? invalid : null;
    },
    save() {
      if (
        this.phone !== this.user.phone ||
        this.classOf !== this.user.classOf ||
        this.bio !== this.user.bio ||
        (this.img !== this.user.img_url &&
          this.img !== this.$store.state.filler_img)
      )
        this.update_personal({
          classOf: this.classOf,
          phone: this.phone,
          img: this.img,
          bio: this.bio,
        });
      this.edited = false;
    },
    async autosave(prop, value) {
      await this.update_application_field({prop, value});
    },
    checkEdit() {
      if (
        (this.img !== this.user.img_url &&
          this.img !== this.$store.state.filler_img) ||
        this.phone !== this.user.phone ||
        this.classOf !== this.user.classOf ||
        this.bio !== this.user.bio
      ) {
        this.edited = true;
      } else this.edited = false;
    },
    async fileInput(event) {
      const file = event.target.files[0];
      this.myFile = file;
      try {
        if (file && file.name) {
          this.processing = true;

          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.addEventListener("load", () => {
            // this is to load image on the UI
            // .. not related to file upload :)
            this.img = fr.result;
          });
          const imgData = new FormData();
          imgData.append("image", this.myFile);
          const filePath = `images/users/${
            this.user.username
          }/profile_picture/${Date.now()}-${file.name}`;
          const metadata = { contentType: this.myFile.type };
          const storage = getStorage();
          const profileImageRef = ref(storage, filePath);
          const uploadTask = uploadBytesResumable(
            profileImageRef,
            this.myFile,
            metadata
          );
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              
            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case "storage/unauthorized":
                  // User doesn't have permission to access the object
                  break;
                case "storage/canceled":
                  // User canceled the upload
                  break;
                case "storage/unknown":
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                this.img = downloadURL;
              });
            }
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.processing = false;
      }
    },
  },
};
</script>
